<template>
  <v-autocomplete
    :value="value"
    dense
    outlined
    label="Cidade"
    :items="cidades"
    item-text="descricao"
    item-value="id"
    :loading="carregando"
    :disabled="(carregando || cidades.length <= 0) && errorMessages.length <= 0"
    :error-messages="errorMessages"
    autocomplete="nope"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import cidadesApi from '@/api/cidades'

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      estadoId: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
    },

    data () {
      return {
        cidades: [],
        carregando: false,
      }
    },

    watch: {
      estadoId: {
        immediate: true,
        handler (val) {
          if (!val) return

          this.buscarCidades()
        },
      },
    },

    methods: {
      async buscarCidades () {
        try {
          this.carregando = true

          const resposta = await cidadesApi.listar({
            estado_id: this.estadoId,
            por_pagina: 999,
          })

          this.cidades = resposta.data.cidades
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },
  }
</script>
